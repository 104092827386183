<template>
  <div class="bottom">
    <div class="honour">
      <img src="http://icon.szfw.org/sf.png" alt="">
    </div>
    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备20012389号-4</a>
  
  </div>
  
</template>

<script>
export default {
    name:"bottom"
}
</script>

<style scoped>
.bottom{
  width: 100%;
  height: 130px;
  background-color: #f4f4f4;
  text-align: center;
  padding-top: 50px;
  
}
.bottom>a{
  text-decoration: none;
  color: rgb(104, 100, 104);
} 
a:hover{
  color: black;
}
.honour{
  border: 0px;
  margin-bottom: 15px;
}
</style>