<template>
  <div class="content">
    <div class="items">
      <div class="item">
        <div>
          <img src="../assets/travel.png" alt="" />
        </div>
        <h4>文旅属性</h4>
        <span>积分可用于乘坐火车、飞机、酒店、景区等</span>
      </div>
      <div class="item">
        <div>
          <img src="../assets/socialContact.png" alt=""  />
        </div>
        <h4>社交属性</h4>
        <span>通过组队分享等方式，获取活跃度</span>
      </div>
      <div class="item">
        <div>
          <img src="../assets/TASK.png" alt="" />
        </div>
        <h4>任务属性</h4>
        <span>通过参与任务，获取相应的积分</span>
      </div>
    </div>
    <div class="line1">

    </div>
    <div class="line2">

    </div>
    <div class="restitems">
      <div class="container">
        <div class="item">
          <div>
            <img src="../assets/shopping.png" alt="" />
          </div>
          <h4>电商属性</h4>
          <span>积分可用于商品购买商品</span>
        </div>
        <div class="item">
          <div>
            <img src="../assets/entertainment.png" alt="" />
          </div>
          <h4>娱乐属性</h4>
          <span>玩法多样，文旅与娱乐相结合</span>
        </div>
      </div>

    </div>
   
  </div>

</template>

<script>
export default {
  name: "appAttribute",
};
</script>

<style scoped>
.content {
  width: 1200px;
  height: 400px;
  margin: 0px auto 0px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.items {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.line1 {
  height: 50px;
  border-bottom: 1px solid #CCCCCC;
}

.line2 {
  height: 50px;
}

.restitems {
  width: 800px;
  margin: 0px auto 0px auto;
}

.item {
  text-align: center;
  width: 350px;
}

.item>h4 {
  margin-top: 10px;
}

.item>span {
  display: inline-block;
  margin-top: 10px;
}
img  {
  width: 60px;
}


</style>