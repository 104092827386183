<template>
  <div id="app">
   <topBar/>
   <main-content/>
   <appAttribute/>
  
   <bottom/>
   

  </div>
</template>

<script>
import appAttribute from './components/appAttribute'
import topBar from './components/topBar'
import mainContent from './components/mainContent'
import bottom from './components/bottom'


export default {
  name: "App",
  components: {appAttribute,bottom,mainContent,topBar},
};
</script>

<style>
*{
    padding: 0px;
    margin: 0px;
}
</style>
