<template>
  <div class="mainContent">
    <div class="phone">
      <div class="Pic">
        <!-- swiper -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><img src="../assets/pic3.jpg" alt=""></div>
            <div class="swiper-slide"><img src="../assets/pic2.jpg" alt=""></div>
            <div class="swiper-slide"><img src="../assets/pic1.jpg" alt=""></div>
            <div class="swiper-slide"><img src="../assets/pic4.jpg" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div class="icon">
      <div class="changepic">
        <img
          class="ercode animate__animated animate__bounce"
          enter-active-class="animate__bounceInLeft"
          v-show="!isShow"
          src="../assets/ercode.png"
          alt=""
        />
        <img v-show="isShow" class="iconimg" src="../assets/icon.png" alt="" />
      </div>
      <div
        class="download"
        @mouseenter="enter"
        @mouseout="out"
        @click.stop="showmore"
      >
        <img src="../assets/download.png" alt="" />
      </div>
    </div>
    <div class="phonePic">
      <img src="../assets/phonenew.png" alt="" />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import "animate.css";
export default {
  name: "mainContent",
  data() {
    return {
      isShow: true,
      c: 1,
    };
  },
  methods: {
    enter() {
      if (this.c === 1) {
        this.isShow = false;
      } else {
        return;
      }
    },
    out() {
      if (this.c === 1) {
        this.isShow = true;
      } else {
        return;
      }
    },
    showmore() {
      this.isShow = false;
      this.c = 2;
    },
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      autoplay:{delay: 2000},
   
      loop: true, 
    });
  },
};
</script>

<style scoped>
.mainContent {
  width: 100%;
  height: 800px;
  background-color: black;
  background-image: url("../assets/backgroundImg.png");
  background-size: 100% 800px;
  overflow: hidden;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-flow: 1;
  margin-top: 81px;
}
.phone {
  align-self: center;
  margin-left: 276px;
}
.icon {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 276px;
  justify-content: start;
}
.icon > div {
  align-self: center;
}
.download {
  margin-top: 30px;
}
.download > img:hover {
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.Pic {
  width: 294px;
  border-radius: 25px;
  background-color: black;
  overflow: hidden;
}

.phonePic {
  position: absolute;
  left: 260px;
  top: 150px;
  z-index: 100;
}
.changepic {
  width: 579px;
  height: 600px;
  text-align: center;
}
.ercode {
  margin-top: 230px;

  animation-duration: 1s; /* don't forget to set a duration! */
}
.swiper-container {
  width: 294px;
}
.swiper-slide>img{
  width: 100%;
}
</style>