<template>
  <div class="topbar ">
    <div class="top">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <span>不积跬步 无以至千里</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "topBar",
};
</script>

<style scoped>
.topbar{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 1000;
}
.top {
  height: 81px;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  margin-left: 60px;
}
.top > span {
  margin-right: 60px;
}
.logo > img {
  width: 100%;
}

</style>